var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body_section"},[_c('profileHeader',{attrs:{"showFilter":false}}),_c('div',{staticClass:"body_content"},[_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"form_subheader"},[_c('h5',[_c('router-link',{attrs:{"to":"/notes"}},[_c('img',{attrs:{"src":require("@/assets/images/return.svg")}})]),_vm._v(_vm._s(_vm.pageTitle)+" ")],1)]),_c('div',{staticClass:"form_section add_note"},[_c('ValidationObserver',{ref:"addNoteForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('b-form-radio-group',{staticClass:"mb-3",attrs:{"options":_vm.note_access_level,"value-field":"_id","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.Payload.accessLevelId),callback:function ($$v) {_vm.$set(_vm.Payload, "accessLevelId", $$v)},expression:"Payload.accessLevelId"}})],1),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('span',{staticClass:"devider"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Subject")]),_c('ValidationProvider',{attrs:{"name":"Subject","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.title),expression:"Payload.title"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.Payload.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "title", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Details")]),_c('ValidationProvider',{attrs:{"name":"Details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.description),expression:"Payload.description"}],staticClass:"form-textarea",attrs:{"rows":"4"},domProps:{"value":(_vm.Payload.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "description", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),(_vm.Payload.accessLevelId == 3)?[_c('span',{staticClass:"devider"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col "},[_c('div',{staticClass:"clients"},[_c('div',{staticClass:"form-group "},[_c('label',{staticClass:"form_label"},[_vm._v("Assign To")]),_c('div',{staticClass:"client_search "},[_c('multiselect',{attrs:{"value":'_id',"options":_vm.originalCliList,"searchable":true,"close-on-select":false,"allow-empty":true,"multiple":true,"label":"name","placeholder":"Select Tagged To","track-by":"name"},on:{"search-change":_vm.asyncFind},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__selectcustom"},[_vm._v(_vm._s(values.length)+" selected")]):_vm._e(),(values.length && isOpen)?_c('span',{staticClass:"multiselect__selectcustom"}):_vm._e()]}}],null,false,302690479),model:{value:(_vm.Payload.taggedToDetails),callback:function ($$v) {_vm.$set(_vm.Payload, "taggedToDetails", $$v)},expression:"Payload.taggedToDetails"}})],1)])])])])]:_vm._e(),_c('span',{staticClass:"devider"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group marb10"},[_c('label',{staticClass:"form_label"},[_vm._v("Documents")]),_c('div',{staticClass:"upload_sec"},[_c('uploadFile',{attrs:{"allowMultiple":true,"acceptedFiles":'*',"takeIndex":false,"payLoadKey":'document',"payLoadDocumentsKey":'documents'},on:{"onUploadComplete":_vm.onUploadComplete}})],1),_c('ul',{staticClass:"uploaded-list"},[_vm._l((_vm.Payload.document),function(other,indx){return [_c('li',{key:indx},[_vm._v(" "+_vm._s(other.name)+" "),_c('span',{on:{"click":function($event){return _vm.removeItem(indx, _vm.Payload.document)}}},[_vm._v("X")])])]})],2)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"secondary_btn cancel"},[_c('router-link',{attrs:{"to":"/notes"}},[_vm._v(" Cancel ")])],1),(_vm.isEdit == false)?_c('button',{staticClass:"primary_btn",attrs:{"disabled":_vm.checkForm},on:{"click":function($event){return _vm.addNote()}}},[_vm._v(" Submit ")]):_vm._e(),(_vm.isEdit == true)?_c('button',{staticClass:"primary_btn",attrs:{"disabled":_vm.checkForm},on:{"click":function($event){return _vm.upDateNote()}}},[_vm._v(" Submit ")]):_vm._e()])])])],2)])],1)]),_c('footerView',{ref:"rout_view"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }